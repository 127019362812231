<template>
  <div class="animated fadeIn">
    <b-card
      v-for="(group, key) in groups"
      :key="key"
      no-body
    >
      <b-card-header v-b-toggle="key">
        {{ key }}
      </b-card-header>
      <b-collapse
        :id="key"
        visible
        class="card-body"
      >
        <b-form @submit.prevent="onSubmit(group)">
          <b-row>
            <b-col
              md="6"
            >
              <b-form-group>
                <h5>На почту</h5>
              </b-form-group>
              <b-form-group v-if="Object.keys(group.emailSettings.settings).length != 0">
                <b-form-checkbox
                  v-model="group.emailSettings.all"
                  :indeterminate.sync="group.emailSettings.intermediate"
                  @change="toggleSubgroup(group.emailSettings)"
                >
                  Все
                </b-form-checkbox> 
              </b-form-group>
              <b-form-group
                v-for="(setting, keySetting) in group.emailSettings.settings"
                :key="keySetting"
              >
                <b-form-checkbox
                  v-model="setting.value"
                  @input="recalcSubgroup(group.emailSettings)"
                >
                  {{ setting.option.name }}
                </b-form-checkbox>
              </b-form-group> 
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group>
                <h5>На сайте</h5>
              </b-form-group>
              <b-form-group v-if="Object.keys(group.pushSettings.settings).length != 0">
                <b-form-checkbox
                  v-model="group.pushSettings.all"
                  :indeterminate.sync="group.pushSettings.intermediate"
                  @change="toggleSubgroup(group.pushSettings)"
                >
                  Все
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                v-for="(setting, keySetting) in group.pushSettings.settings"
                :key="keySetting"
              >
                <b-form-checkbox
                  v-model="setting.value"
                  @input="recalcSubgroup(group.pushSettings)"
                >
                  {{ setting.option.name }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            type="submit"
            variant="primary"
            :disabled="loading"
            :class="{'loading' : loading}"
          >
            Сохранить
          </b-button>
        </b-form>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import notifications from '../../components/mixins/notifications';
import {
  readCustomerUserNotificationsSettings,
  readCustomerUserNotificationsSettingsOptions,
  setCustomerUserNotificationsSettings,
} from '../../services/api';

export default {
  name: 'CustomerNotificationsSettings',
  mixins: [notifications],
  data() {
    return {
      settings: {},
      loading: false,
      groups: {},
      hiddenOptions: [
          'email_notification_auctionAwait', 
          'push_notification_auctionAwait',
          'email_notification_auctionStart',
          'push_notification_auctionStart',
          'email_notification_auctionClosed',
          'push_notification_auctionClosed', 
          'email_notification_auctionCanceled',
          'push_notification_auctionCanceled',
          'push_notification_auctionDrafted',
          'email_notification_auctionDrafted',
          'push_notification_auctionCancelWarning',
          'push_notification_auctionWithOffersStopped',
          'push_notification_tenderAwait', 
          'push_notification_tenderStart',
          'email_notification_tenderStart',
          'push_notification_tenderCanceled',
          'push_notification_tenderDrafted',
          'email_notification_tenderDrafted',
          'push_notification_routeDirty',
          'email_notification_routeDirty',
          'push_notification_routeReady',
          'email_notification_routeReady',
          'push_notification_newRouteCancellationRequest',
          'email_notification_newRouteCancellationRequest',
          'push_notification_routeSendToAuction',
          'email_notification_routeSendToAuction',
          'push_notification_routeRecountSumm',
          'email_notification_routeRecountSumm',
          'push_notification_driverSendToCheck',
          'push_notification_contractorDriverCreated',
          'push_notification_driverDraftCreate',
          'push_notification_appealCreated',
          'email_notification_appealCreated',
      ],
    };
  },

  mounted() {
    this.readCustomerUserNotificationsSettingsOptions();
  },
  methods: {
    async readCustomerUserNotificationsSettings() {
      this.loading = true;
      const response = await readCustomerUserNotificationsSettings(
        this.$store.state.user.userId,
      );
      if (response && response.status === 200) {
        response.data.forEach((setting) => {
          if (this.hiddenOptions.includes(setting.option.code)) { 
            return;
          }
          this.settings[setting.option.code] = setting;
          this.groups[setting.option.settingsGroup.name].settings[setting.option.code] = setting;
          if (setting.option.code.includes('email')) {
            this.groups[setting.option.settingsGroup.name].emailSettings.settings[setting.option.code] = setting;
          } else if (setting.option.code.includes('push')) {
            this.groups[setting.option.settingsGroup.name].pushSettings.settings[setting.option.code] = setting;
          }
        });
      }
      Object.values(this.groups).forEach((group)=>{
        this.recalcSubgroup(group.emailSettings);
        this.recalcSubgroup(group.pushSettings);
      });
      this.loading = false;
      this.$forceUpdate();
    },
    async readCustomerUserNotificationsSettingsOptions() {
      this.loading = true;
      const response = await readCustomerUserNotificationsSettingsOptions();
      if (response && response.status === 200) {
        response.data.forEach((option) => {
          const dataOption = {
            option: option,
            value: option.defaultValue,
            customerUser: {
              id: this.$store.state.user.userId,
            },
          };
          if (this.hiddenOptions.includes(option.code)) { 
            return;
          }
          this.settings[option.code] = dataOption;
          if (!this.groups[option.settingsGroup.name]) {
            this.groups[option.settingsGroup.name]={};
            this.groups[option.settingsGroup.name].settings = {};
            this.groups[option.settingsGroup.name].emailSettings = {
              settings: {},
              all: false,
              intermediate: false,
            };

            this.groups[option.settingsGroup.name].pushSettings = {
              settings: {},
              all: false,
              intermediate: false,
            };
          }
          this.groups[option.settingsGroup.name].settings[option.code] = dataOption;
          if (option.code.includes('email')) {
            this.groups[option.settingsGroup.name].emailSettings.settings[option.code] = dataOption;
          } else if (option.code.includes('push')) {
            this.groups[option.settingsGroup.name].pushSettings.settings[option.code] = dataOption;
          }
        });
      }
      this.readCustomerUserNotificationsSettings();
    },
    async onSubmit(group) {
      this.loading = true;
      const submitGroupSettings = Object.values(group.settings);
      const customerUserSettingsOptions = submitGroupSettings.map((item) => {
        return item.option.settingsGroup.customerUserSettingsOptions.filter((settingOption) => {
          if (isNaN(settingOption)) {
            delete settingOption.settingsGroup;
            return Object.assign(settingOption);
          }
        });
      });
      submitGroupSettings.forEach((item, index) => {
        item.option.settingsGroup.customerUserSettingsOptions = customerUserSettingsOptions[index];
      });
      const response = await setCustomerUserNotificationsSettings(
        this.$store.state.user.userId,
        Object.values(submitGroupSettings),
      );
      if (response && response.status === 200) {
        response.data.forEach((setting) => {
          if (this.hiddenOptions.includes(setting.option.code)) { 
            return;
          }
          this.settings[setting.option.code] = setting;
        });
        this.showSuccess('Настройки обновлены');
      }
      this.loading = false;
      this.$forceUpdate();
    },
    recalcSubgroup(subgroup) {
      const all = Object.values(subgroup.settings).reduce((accumulator, currentValue) => {
        return accumulator && !!currentValue.value;
      }, false);
      let intermediate = false;
      let prevValue = null;
      for (const value of Object.values(subgroup.settings)) {
        if (prevValue===null) {
          prevValue = !!value.value;
        } else if (prevValue!== !!value.value) {
          intermediate = true;
          break;
        } else {
          prevValue= !!value.value;
        }
      }
      subgroup.all = all;
      subgroup.intermediate = intermediate;
      this.$forceUpdate();
    },
    toggleSubgroup(subgroup) {
      const newVal = subgroup.all;
      Object.values(subgroup.settings).forEach((setting)=>{
        if (this.hiddenOptions.includes(setting.option.code)) { 
            return;
          }
        if (setting.option.code.includes('email')) {
          this.groups[setting.option.settingsGroup.name].emailSettings.settings[setting.option.code].value = newVal;
        } else if (setting.option.code.includes('push')) {
          this.groups[setting.option.settingsGroup.name].pushSettings.settings[setting.option.code].value = newVal;
        }
      });
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
</style>
